import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from "react-markdown"
import { gql, useMutation } from "@apollo/client"

const CallToAction = () => {
  const callToActiondata = useStaticQuery(graphql`
    query CallToActionQuery {
      strapiGlobalData {
        subscribeForm
        subscribeFormBtnTxt
      }
    }
  `)

  const SUBSCRIBE_TO_NEWSLETTER = gql`
    mutation createSubscribe($input: createSubscribeInput) {
      createSubscribe(input: $input) {
        subscribe {
          email
        }
      }
    }
  `
  const [errorsState, setErrors] = useState()

  let emailInput
  const [subscribeToAngularNewsletter, { data }] = useMutation(
    SUBSCRIBE_TO_NEWSLETTER
  )

  const postSubscribeContent = {
    marginTop: "15px",
  }

  const separator = {
    backgroundColor: "#ffffff",
  }

  const emailLabel = {
    display: "block",
    color: "red",
    textAlign: "left",
  }

  function handleSubmit(email) {
    //Email
    if (!email) {
      setErrors("Cannot be empty")
      return
    }

    if (typeof email !== "undefined") {
      let lastAtPos = email.lastIndexOf("@")
      let lastDotPos = email.lastIndexOf(".")

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf("@@") == -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        setErrors("Email is not valid")
        return
      }
    }

    emailInput.value = ""

    subscribeToAngularNewsletter({
      variables: {
        input: {
          data: {
            email: email,
            listId: "46594b23-205d-436d-b844-80d276fa66bf",
          },
        },
      },
    })
  }

  return (
    <div className="call-to-action">
      <div className="call-to-action-content">
        <ReactMarkdown
          source={callToActiondata.strapiGlobalData.subscribeForm || "N/A"}
        />
        {!data && (
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(emailInput.value)
            }}
          >
            {errorsState && <label style={emailLabel}>*{errorsState}</label>}
            <div className="form-subscribe">
              <input
                type="text"
                className="form-control"
                placeholder="Please enter your email address"
                ref={node => {
                  emailInput = node
                }}
              />
              <button type="submit" className="btn btn-primary">
                {callToActiondata.strapiGlobalData.subscribeFormBtnTxt}
              </button>
            </div>
          </form>
        )}

        {data && (
          <div>
            <hr style={separator} />
            <div style={postSubscribeContent}>
              <h4>
                Look at you go! {data.createSubscribe.subscribe.email} is
                subscribed!!!
              </h4>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CallToAction
