import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import slugify from "slugify"
import { Link } from "@reach/router"

class NewsletterCard extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    sendDate: PropTypes.string.isRequired,
  }

  render() {
    const { title, sendDate } = this.props

    const newsletterCard = {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      minHeight: "100%",
    }

    const newsletterCardContent = {
      padding: "1.75rem",
      flex: "1",
      backgroundColor: "#0c4b40",
    }

    const newsletterCardContentSpan = {
      fontWeight: "500",
      color: "#FDE4E0",
    }

    const newsletterCardDate = {
      display: "inline-block",
      fontSize: "0.875rem",
      fontWeight: "400",
      textDecoration: "none",
      color: "rgba(253, 228, 224, 0.5)",
      transitionTimingFunction: "ease",
      transitionProperty: "color",
      transitionDuration: "100ms",
    }

    const newsletterCardDateH6 = {
      marginTop: "1.25rem",
      fontSize: "1.425rem",
      fontWeight: "500",
      lineHeight: "1.325",
    }

    const newsletterCardDateH6Link = {
      fontWeight: "500",
      color: "rgb(253, 228, 224)",
      transitionTimingFunction: "ease",
      transitionProperty: "color, background",
      transitionDurationstyle: "100ms",
    }

    return (
      <div style={newsletterCard}>
        <div style={newsletterCardContent}>
          <Link
            style={newsletterCardDate}
            to={`/${slugify(title.toLowerCase())}/`}
          >
            released <span style={newsletterCardContentSpan}>{sendDate}</span>
          </Link>
          <h6 style={newsletterCardDateH6}>
            <Link
              style={newsletterCardDateH6Link}
              to={`/${slugify(title.toLowerCase())}/`}
            >
              {title}
            </Link>
          </h6>
        </div>
      </div>
    )
  }
}

export default NewsletterCard
