import React from "react"
import Layout from "../components/layout"
import Menu from "../components/global/Menu"
import Footer from "../components/global/Footer"
import { graphql } from "gatsby"
import moment from "moment"
import SEO from "../components/seo"
import NewsletterCard from "../components/global/NewsletterCard"
import CallToAction from "../components/global/CallToAction"

let darkMode =
  typeof window !== `undefined` ? window.localStorage.getItem("darkMode") : null

const NewsletterPage = ({
  data: { allStrapiNewsletterArticlesSingleSends },
}) => {
  return (
    <Layout
      bodyTheme={darkMode === "true" ? "theme-blog-dark" : "theme-blog-light"}
    >
      <SEO title="Newsletters" description="Razroo Newsletter Archive" />
      <header>
        <Menu darkModeToggle="true" darkMode={darkMode === "true"} />
      </header>
      <main className="page-wrapper">
        <section className="section section-first">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-heading text-center">
                  <div className="section-heading-text">
                    <h1>Newsletter Archive</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-room">
                  <div className="blog-room-list">
                    <div className="grid grid-1 grid-sm-2 grid-lg-4 grid-small-articles">
                      {allStrapiNewsletterArticlesSingleSends.edges.map(
                        ({ node }, i) => {
                          let sendDate = moment(node["send_at"]).format("LL")

                          return (
                            <div className="grid-item" key={i}>
                              <NewsletterCard
                                title={node["name"]}
                                sendDate={sendDate}
                              />
                            </div>
                          )
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <CallToAction />
          </div>
        </section>

        <Footer />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query allStrapiNewsletterArticlesSingleSendsQuery {
    allStrapiNewsletterArticlesSingleSends {
      edges {
        node {
          name
          strapiId
          send_at
        }
      }
    }
  }
`

export default NewsletterPage
